@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500,700);
/**
 * Sets the font-size and line-height based on the related CSS Variables
 * Accepts an optional $override-line-height argument which
 * allows the user to override the line-height. Useful if you've got
 * two different font-sizes next to each other and you need the
 * line-heights to be the same.
 *
 * Accepts the following for each argument:
 * xsmall
 * small
 * medium
 * large
 * xlarge
 * xxlarge
 */
/**
 * Sets the font-weight based on the related CSS Variables
 *
 * Accepts the following for $font-weight:
 * light
 * regular (default)
 * semibold
 * bold
 * black
 */
/* stylelint-disable */
.styles_container__3eMNV {
  display: grid;
  -webkit-align-items: center;
          align-items: center;
  justify-items: center;
  width: 100%;
  height: 100%; }

.styles_spinner__2ec8c {
  width: 1em;
  height: 1em;
  -webkit-animation: styles_spin__30lth 1s linear infinite;
          animation: styles_spin__30lth 1s linear infinite;
  border: 1px solid rgba(38, 160, 218, 0.2);
  border-radius: 50%;
  border-top-color: var(--primary-colour); }

@-webkit-keyframes styles_spin__30lth {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes styles_spin__30lth {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

/**
 * Sets the font-size and line-height based on the related CSS Variables
 * Accepts an optional $override-line-height argument which
 * allows the user to override the line-height. Useful if you've got
 * two different font-sizes next to each other and you need the
 * line-heights to be the same.
 *
 * Accepts the following for each argument:
 * xsmall
 * small
 * medium
 * large
 * xlarge
 * xxlarge
 */
/**
 * Sets the font-weight based on the related CSS Variables
 *
 * Accepts the following for $font-weight:
 * light
 * regular (default)
 * semibold
 * bold
 * black
 */
/* stylelint-disable */
.styles_container__gqMfF {
  height: 100%;
  background: var(--main-background-colour);
  color: var(--main-copy-colour);
  font-size: var(--font-size-medium) !important;
  line-height: var(--line-height-medium) !important; }

/**
 * Sets the font-size and line-height based on the related CSS Variables
 * Accepts an optional $override-line-height argument which
 * allows the user to override the line-height. Useful if you've got
 * two different font-sizes next to each other and you need the
 * line-heights to be the same.
 *
 * Accepts the following for each argument:
 * xsmall
 * small
 * medium
 * large
 * xlarge
 * xxlarge
 */
/**
 * Sets the font-weight based on the related CSS Variables
 *
 * Accepts the following for $font-weight:
 * light
 * regular (default)
 * semibold
 * bold
 * black
 */
/* stylelint-disable */
:root {
  --font-sans-serif: -apple-system, BlinkMacSystemFont, 'avenir next', avenir,
    'helvetica neue', helvetica, Ubuntu, roboto, noto, 'segoe ui', arial,
    sans-serif;
  --font-serif: 'Iowan Old Style', 'Apple Garamond', Baskerville,
    'Times New Roman', 'Droid Serif', Times, 'Source Serif Pro', serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  --font-monospace: Menlo, Consolas, Monaco, 'Liberation Mono', 'Lucida Console',
    monospace;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-semibold: 500;
  --font-weight-bold: 600;
  --font-weight-black: 700;
  /**
   * Nimvelo Colours
   * Should never change, or be used outside of this file
   */
  --flare: #ff465c;
  --sol: #ff8b35;
  --sunflower: #ffdd06;
  --kerman: #95f661;
  --aurora: #00e99f;
  --celeste: #00d5cf;
  --sky-blue: #26a0da;
  --nebula: #9f82e2;
  --tourmaline: #f266ac;
  --sky-at-night: #314755;
  --jet: #252527;
  --charcoal: #39393b;
  --gun-powder: #4d4d4f;
  --storm: #79797b;
  --cgp: #a8a8aa;
  --overcast: #d9d9dc;
  --corona: #f3f3f5;
  --cirrus: #fafafb;
  --flare-copy: #ffffff;
  --sol-copy: #ffffff;
  --sunflower-copy: #4d4d4f;
  --kerman-copy: #ffffff;
  --aurora-copy: #ffffff;
  --celeste-copy: #4d4d4f;
  --sky-blue-copy: #ffffff;
  --nebula-copy: #ffffff;
  --tourmaline-copy: #ffffff;
  --sky-at-night-copy: #ffffff;
  --jet-copy: #ffffff;
  --charcoal-copy: #ffffff;
  --gun-powder-copy: #ffffff;
  --storm-copy: #ffffff;
  --cgp-copy: #4d4d4f;
  --overcast-copy: #4d4d4f;
  --corona-copy: #4d4d4f;
  --cirrus-copy: #4d4d4f;
  /**
   * Brand Grey Colours
   * Should be referenced instead of above colours
   * Eg. From now on reference --black-colour instead of --jet
   */
  --black-colour: var(--jet);
  --off-black-colour: var(--charcoal);
  --darker-grey-colour: var(--gun-powder);
  --dark-grey-colour: var(--storm);
  --light-grey-colour: var(--cgp);
  --lighter-grey-colour: var(--overcast);
  --lightest-grey-colour: var(--corona);
  --off-white-colour: var(--cirrus);
  --white-colour: #ffffff;
  /**
   * Brand Application Colours
   */
  --dark-copy-colour: var(--darker-grey-colour);
  --light-copy-colour: var(--white-colour);
  --primary-colour: var(--sky-blue);
  --primary-colour-copy: var(--sky-blue-copy);
  --secondary-colour: var(--sky-at-night);
  --secondary-colour-copy: var(--sky-at-night-copy);
  --positive-colour: var(--aurora);
  --positive-colour-copy: var(--aurora-copy);
  --warning-colour: var(--sunflower);
  --warning-colour-copy: var(--sunflower-copy);
  --danger-colour: var(--flare);
  --danger-colour-copy: var(--flare-copy);
  --attention-colour: var(--sol);
  --attention-colour-copy: var(--sol-copy);
  /**
   * Theme-Specific Colours
   */
  --main-background-colour: var(--white-colour);
  --main-off-background-colour: var(--cirrus);
  --main-copy-colour: var(--darker-grey-colour);
  --main-off-copy-colour: var(--dark-grey-colour);
  --main-outline-colour: var(--lighter-grey-colour);
  /**
   * Font sizes and leading taken from Apple's Human Interface Guidelines
   * https://web.archive.org/web/20180130103301/https://developer.apple.com/ios/human-interface-guidelines/visual-design/typography/
   */
  --font-size-xsmall: 12px;
  --font-size-small: 14px;
  --font-size-medium: 15px;
  --font-size-large: 18px;
  --font-size-xlarge: 20px;
  --font-size-xxlarge: 26px;
  --line-height-xsmall: 16px;
  --line-height-small: 19px;
  --line-height-medium: 20px;
  --line-height-large: 22px;
  --line-height-xlarge: 25px;
  --line-height-xxlarge: 32px; }

html {
  box-sizing: border-box;
  height: 100%;
  color: #4d4d4f;
  color: var(--main-copy-colour);
  font-family: -apple-system, BlinkMacSystemFont, 'avenir next', avenir,
    'helvetica neue', helvetica, Ubuntu, roboto, noto, 'segoe ui', arial,
    sans-serif;
  font-family: var(--font-sans-serif);
  font-weight: 400 !important;
  font-weight: var(--font-weight-regular) !important; }

body {
  height: 100%;
  margin: 0;
  padding: 0; }

pre {
  overflow-x: auto; }

#root {
  height: 100%; }

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility; }

*,
*:before,
*:after {
  box-sizing: inherit; }

.tabular {
  font-family: Menlo, Consolas, Monaco, 'Liberation Mono', 'Lucida Console',
    monospace;
  font-family: var(--font-monospace); }

