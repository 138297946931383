@import 'src/scss/scss';

.container {
  height: 100%;

  background: var(--main-background-colour);

  color: var(--main-copy-colour);
  @include font-size(medium);
}
