@import 'src/scss/scss';

.container {
  display: grid;
  align-items: center;
  justify-items: center;

  width: 100%;
  height: 100%;
}

.spinner {
  width: 1em;
  height: 1em;

  animation: spin 1s linear infinite;

  border: 1px solid rgba(38, 160, 218, 0.2); // TODO
  border-radius: 50%;
  border-top-color: var(--primary-colour);
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
